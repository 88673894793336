define("static-app/routes/psychotherapeut", ["exports", "static-app/mixins/scroll-to-top"], function (_exports, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollToTop.default, {});

  _exports.default = _default;
});