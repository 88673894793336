define("static-app/components/auto-date", ["exports", "moment", "ember-local-storage"], function (_exports, _moment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    moment: (0, _moment.default)(),
    actualisation: (0, _emberLocalStorage.storageFor)('actualisation'),
    actualisationDate: null,
    formattedActualisation: null,

    willRender() {
      let now = (0, _moment.default)()._d;

      let diff = (0, _moment.default)(new Date(this.get('actualisation.date'))).diff(now, 'days'); // If now localstorage is available set on now

      if (!this.get('actualisation.date')) {
        this.set('actualisation.date', now);
        this.set('actualisationDate', now);
      } else {
        if (diff <= -31) {
          this.set('actualisation.date', now);
        }

        this.set('actualisationDate', this.get('actualisation.date'));
      }

      let formattedActualisation = (0, _moment.default)(new Date(this.get('actualisationDate'))).format("D MMM YYYY");
      this.set('formattedActualisation', formattedActualisation);
    }

  });

  _exports.default = _default;
});