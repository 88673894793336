define("static-app/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute("index"), this.use("toLeft"), this.reverse("toRight")); // End global animations

    this.transition(this.fromRoute(["psychotherapeut", "praktijk", "informatie", "contact", "links"]), this.toRoute(["psychotherapeut", "praktijk", "informatie", "contact", "links"]), this.use("crossFade"), this.reverse("crossFade"));
  }
});