define("static-app/templates/components/single-painting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nshWvCnD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"painting-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n        \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"painting\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"sizes\",\"(max-width: 526px) 100vw, 526px\"],[11,\"srcset\",[29,[\"\\n                images/paintings/\\n                \",[22,\"imagename\"],\"\\n                -200.jpg 200w,\\n                images/paintings/\\n                \",[22,\"imagename\"],\"\\n                -347.jpg 347w,\\n                images/paintings/\\n                \",[22,\"imagename\"],\"\\n                -449.jpg 449w,\\n                images/paintings/\\n                \",[22,\"imagename\"],\"\\n                -526.jpg 526w\"]]],[11,\"src\",[29,[\"images/paintings/\",[22,\"imagename\"],\"-526.jpg\"]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"link\"],[8],[0,\"\\n        \"],[7,\"a\",true],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[11,\"href\",[29,[\"http://\",[22,\"link\"]]]],[10,\"target\",\"_blank\"],[8],[0,\"\\n            \"],[1,[22,\"link\"],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/single-painting.hbs"
    }
  });

  _exports.default = _default;
});