define("static-app/templates/components/read-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dlenUHLJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"read-more-container \",[28,\"if\",[[24,[\"expanded\"]],\"expanded\"],null]]]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"expanded\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\"read-more-less\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n        Lees minder\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\"read-more-less\"],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"toggle\"]],[8],[0,\"\\n        Lees meer\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/read-more.hbs"
    }
  });

  _exports.default = _default;
});