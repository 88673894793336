define("static-app/components/read-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    expanded: false,
    safeString: Ember.computed("content", function () {
      return Ember.String.htmlSafe(this.content);
    }),
    actions: {
      toggle() {
        this.toggleProperty("expanded");
      }

    }
  });

  _exports.default = _default;
});