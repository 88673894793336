define("static-app/components/footer-copyright", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    moment: (0, _moment.default)(),
    currentYear: (0, _moment.default)().year()
  });

  _exports.default = _default;
});