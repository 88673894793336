define("static-app/templates/components/menu-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HKcd90jp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"modal modal-lg menu-modal \",[28,\"if\",[[24,[\"navigationMenu\",\"showMenu\"]],\"active\"],null]]]],[8],[0,\"\\n    \"],[7,\"a\",true],[10,\"href\",\"#close\"],[10,\"class\",\"modal-overlay\"],[10,\"aria-label\",\"Close\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"modal-container\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"modal-container__links\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"invokeAction\",\"route\"],[\"modal-container__links--link\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"index\"]],{\"statements\":[[0,\"                Welkom\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"invokeAction\",\"route\"],[\"modal-container__links--link\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"psychotherapeut\"]],{\"statements\":[[0,\"                De Psychotherapeut\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"invokeAction\",\"route\"],[\"modal-container__links--link\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"praktijk\"]],{\"statements\":[[0,\"                De Praktijk\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"invokeAction\",\"route\"],[\"modal-container__links--link\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"informatie\"]],{\"statements\":[[0,\"                Kosten en Verzekering\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"invokeAction\",\"route\"],[\"modal-container__links--link\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"contact\"]],{\"statements\":[[0,\"                Contact\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"invokeAction\",\"route\"],[\"modal-container__links--link\",[28,\"action\",[[23,0,[]],\"closeModal\"],null],\"links\"]],{\"statements\":[[0,\"                Links\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/menu-modal.hbs"
    }
  });

  _exports.default = _default;
});