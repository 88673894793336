define("static-app/templates/components/main-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cyUPMQi3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\",true],[10,\"class\",\"container grid-lg navbar\"],[8],[0,\"\\n    \"],[7,\"section\",true],[10,\"class\",\"navbar-section\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"navbar-brand mr-2\",\"index\"]],{\"statements\":[[0,\"            Psychotherapiepraktijk M.L. Burger\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n    \"],[7,\"section\",true],[10,\"class\",\"navbar-section\"],[8],[0,\"\\n        \"],[1,[28,\"menu-modal\",null,[[\"showMenu\"],[[24,[\"showMenu\"]]]]],false],[0,\"\\n\\n        \"],[7,\"div\",false],[12,\"class\",[29,[\"nav-icon\",[28,\"if\",[[24,[\"navigationMenu\",\"menuButton\"]],\" open\"],null]]]],[3,\"action\",[[23,0,[]],\"toggleMenu\"]],[8],[0,\"\\n            \"],[7,\"span\",true],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/main-navigation.hbs"
    }
  });

  _exports.default = _default;
});