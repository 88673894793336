define("static-app/templates/components/painting-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nd4Aje8i",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[8],[0,\"\\n    In de praktijk hangt en staat werk van:\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"columns\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column col-sm-12 col-4\"],[8],[0,\"\\n        \"],[1,[28,\"single-painting\",null,[[\"imagename\",\"title\",\"link\"],[\"carineburger\",\"Carine Burger\",\"www.carineburger.nl\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column col-sm-12 col-4\"],[8],[0,\"\\n        \"],[1,[28,\"single-painting\",null,[[\"imagename\",\"title\",\"link\"],[\"carryvanderkaaij\",\"Carry van der Kaaij\",\"www.fotocarry.com\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"column col-sm-12 col-4\"],[8],[0,\"\\n        \"],[1,[28,\"single-painting\",null,[[\"imagename\",\"title\",\"link\"],[\"karinloenen\",\"Karin Loenen\",\"www.kilart.nl\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"center\"],[8],[0,\"\\n    De foto’s op deze site zijn gemaakt door Carry van der Kaaij.\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/painting-gallery.hbs"
    }
  });

  _exports.default = _default;
});