define("static-app/components/main-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    navigationMenu: Ember.inject.service(),
    classNames: ['main-navigation'],
    actions: {
      toggleMenu() {
        this.toggleProperty('navigationMenu.menuButton');
        this.toggleProperty('navigationMenu.showMenu');
      }

    }
  });

  _exports.default = _default;
});