define("static-app/templates/components/mlb-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tqm+SQox",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"footer-navigation__logo\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n        MLB\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/mlb-logo.hbs"
    }
  });

  _exports.default = _default;
});