define("static-app/templates/components/hero-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XS3T2Spo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[0,\"\\n        \"],[7,\"picture\",true],[10,\"class\",\"image-container__image\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"sizes\",\"(max-width: 1080px) 100vw, 1080px\"],[11,\"srcset\",[29,[\"\\n                    ./images/ambiance/\\n                    \",[22,\"image\"],\"\\n                    /190.jpg 190w,\\n                ./images/ambiance/\\n                    \",[22,\"image\"],\"\\n                    /560.jpg 560w,\\n                ./images/ambiance/\\n                    \",[22,\"image\"],\"\\n                    /810.jpg 810w,\\n               ./images/ambiance/\\n                    \",[22,\"image\"],\"\\n                    /1008.jpg 1008w,\\n               ./images/ambiance/\\n                    \",[22,\"image\"],\"\\n                    /1080.jpg 1080w\"]]],[11,\"src\",[29,[\"./images/ambiance/\",[22,\"image\"],\"/190.jpg\"]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"page-title\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"class\",\"container grid-lg\"],[8],[0,\"\\n                    \"],[7,\"div\",true],[10,\"class\",\"columns\"],[8],[0,\"\\n                        \"],[7,\"div\",true],[10,\"class\",\"column col-12\"],[8],[0,\"\\n                            \"],[7,\"h2\",true],[10,\"class\",\"page-title__title\"],[8],[0,\"\\n                                \"],[1,[22,\"title\"],false],[0,\"\\n                            \"],[9],[0,\"\\n                        \"],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/hero-image.hbs"
    }
  });

  _exports.default = _default;
});