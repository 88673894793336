define("static-app/templates/components/footer-copyright", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BKAEG4LM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"container grid-lg\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"columns\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"column col-12\"],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"copyright\"],[8],[0,\"\\n                    Copyright © \"],[1,[22,\"currentYear\"],false],[0,\" mlburger-psychotherapie.nl\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/footer-copyright.hbs"
    }
  });

  _exports.default = _default;
});