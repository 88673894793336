define("static-app/templates/components/lvvp-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F23fYqQJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"picture\",true],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"sizes\",\"(max-width: 300px) 100vw, 300px\"],[10,\"srcset\",\"[object Object],images/logos/lvvp_vgcprh_c_scale,w_50.png 50w,\\nimages/logos/lvvp_vgcprh_c_scale,w_157.png 157w,\\nimages/logos/lvvp_vgcprh_c_scale,w_230.png 230w,\\nimages/logos/lvvp_vgcprh_c_scale,w_300.png 300w\"],[10,\"src\",\"images/logos/lvvp_vgcprh_c_scale,w_300.png\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/lvvp-logo.hbs"
    }
  });

  _exports.default = _default;
});