define("static-app/components/welcome-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showModal: true,
    classNames: 'modal modal-lg welcome-modal',
    classNameBindings: ['showModal:active'],
    actions: {
      closeModal() {
        this.toggleProperty('showModal');
      }

    }
  });

  _exports.default = _default;
});