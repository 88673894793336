define("static-app/templates/components/welcome-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dBvsaVMO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"href\",\"#close\"],[10,\"class\",\"modal-overlay\"],[10,\"aria-label\",\"Close\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"modal-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"landing-page\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"\\n            Welkom op de website van Psychotherapiepraktijk M.L. Burger\\n        \"],[9],[0,\"\\n        \"],[7,\"h3\",true],[8],[0,\"\\n            Praktijk voor psychotherapie en supervisie\\n        \"],[9],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn\"],[3,\"action\",[[23,0,[]],\"closeModal\"]],[8],[0,\"\\n            Volgende\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "static-app/templates/components/welcome-modal.hbs"
    }
  });

  _exports.default = _default;
});